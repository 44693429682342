import React, { useState } from 'react';
import { db, collection, setDoc, doc } from '../firebase-config';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Set the default icon for Leaflet maps
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const BusStopForm: React.FC = () => {
  const [busStopNumber, setBusStopNumber] = useState<string>('');
  const [latitude, setLatitude] = useState<string>('3.2497415073853473'); // Initial latitude for IIUM
  const [longitude, setLongitude] = useState<string>('101.7389213410722'); // Initial longitude for IIUM
  const [locationName, setLocationName] = useState<string>('');
  const [documentIdPart, setDocumentIdPart] = useState<string>(''); // User input part of document ID
  const [fullDocumentId, setFullDocumentId] = useState<string>(''); // Full document ID
  const [error, setError] = useState<string | null>(null);
  const [submissionStatus, setSubmissionStatus] = useState<string | null>(null);

  // Custom component to handle map click events
  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        setLatitude(e.latlng.lat.toString());
        setLongitude(e.latlng.lng.toString());
      },
    });
    return latitude && longitude ? (
      <Marker position={[parseFloat(latitude), parseFloat(longitude)]}></Marker>
    ) : null;
  };

  // Function to handle form submission
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!documentIdPart) {
      setError('Document ID part is required.');
      return;
    }

    // Construct the full document ID
    const fullId = `E28011052000${documentIdPart}`;
    setFullDocumentId(fullId);

    try {
      // Use setDoc to specify the document ID
      await setDoc(doc(db, 'azp_stop', fullId), {
        bus_stop_number: busStopNumber,
        latitude,
        longitude,
        location_name: locationName,
      });

      setSubmissionStatus('Form submitted successfully!');
      // Optionally, reset form fields
      setBusStopNumber('');
      setLatitude('3.2497415073853473');
      setLongitude('101.7389213410722');
      setLocationName('');
      setDocumentIdPart('');
    } catch (error) {
      console.error('Error adding document: ', error);
      setSubmissionStatus('Failed to submit form.');
    }
  };

  return (
    <div className="container mx-auto p-6 max-w-lg bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl font-semibold text-gray-800 mb-6 text-center">Tag-Me</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Bus Stop Number:</label>
          <input
            type="text"
            value={busStopNumber}
            onChange={(e) => setBusStopNumber(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Location:</label>
          <MapContainer center={[parseFloat(latitude), parseFloat(longitude)]} zoom={16} className="w-full h-64">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <LocationMarker />
          </MapContainer>
          <div className="mt-2">
            <input
              type="text"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
              className="w-full border border-gray-300 rounded-lg p-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Latitude"
              readOnly
            />
            <input
              type="text"
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
              className="w-full border border-gray-300 rounded-lg p-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 mt-2"
              placeholder="Longitude"
              readOnly
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Location Name:</label>
          <input
            type="text"
            value={locationName}
            onChange={(e) => setLocationName(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Document ID Part: E28011052000_____________ | </label>
          <input
            type="text"
            value={documentIdPart}
            onChange={(e) => setDocumentIdPart(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter the part of Document ID"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-gray-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Submit
        </button>
        {error && <p className="mt-2 text-red-500">{error}</p>}
        {submissionStatus && <p className="mt-2 text-green-500">{submissionStatus}</p>}
      </form>
    </div>
  );
};

export default BusStopForm;
