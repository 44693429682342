// src/firebaseConfig.ts
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, setDoc, doc} from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAT9ps5CYFv___A2NSrwPMULpGgrJGeYyw",
    authDomain: "shuttlebusiium.firebaseapp.com",
    projectId: "shuttlebusiium",
    storageBucket: "shuttlebusiium.appspot.com",
    messagingSenderId: "147845763016",
    appId: "1:147845763016:web:7b9669efd4bc2324803afd"  
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection, addDoc, setDoc, doc };